<template>
	<div class="home">
		<section class="home__main">
			<div class="home__main__inner">
				<div v-inview="{ effect: 'fade', delay: '1' }">
					<VLazyImage
						class="home__headline-logo"
						:src="images.headline"
						alt="Odrobaczanie z nami to błahostka!"
						width="534"
						height="233"
					/>
				</div>

				<h1 v-inview="{ effect: 'fade', delay: '3' }" class="home__headline-text">
					Sprawdź, jak często
					<br />
					odrobaczać pupila
				</h1>

				<div class="home__button-wrapper">
					<BaseButton v-inview="{ effect: 'fade', delay: '5' }" :to="{ name: 'questions' }">
						START
					</BaseButton>
				</div>
			</div>

			<picture>
				<source media="(min-width: 1024px)" srcset="@/assets/images/view-home/cat-hd.png" />
				<source media="(min-width: 768px)" srcset="@/assets/images/view-home/cat-tablet.png" />
				<img
					v-inview="{ effect: 'fade-up-long', delay: '7' }"
					:src="images.catMobile"
					alt=""
					class="home__animal-img home__animal-img--cat"
				/>
			</picture>

			<picture>
				<source media="(min-width: 1024px)" srcset="@/assets/images/view-home/dog-hd.png" />
				<source media="(min-width: 768px)" srcset="@/assets/images/view-home/dog-tablet.png" />
				<img
					v-inview="{ effect: 'fade-up-long', delay: '7' }"
					:src="images.dogMobile"
					alt=""
					class="home__animal-img home__animal-img--dog"
				/>
			</picture>
		</section>

		<section class="home__bottom">
			<picture>
				<source
					media="(min-width: 640px)"
					srcset="@/assets/images/view-home/packshots-desktop.png"
				/>

				<VLazyImage :src="images.packshotsMobile" alt="Dronspot opakowanie" class="packshot" />
			</picture>
		</section>

		<footer class="home__footer">
			<div class="home__logos">
				<a href="https://www.drontal.pl/" target="_blank" class="home__single-link">
					<VLazyImage :src="images.logoDrontal" alt="Drontal logo" class="home__single-logo" />
				</a>

				<a href="https://www.dronspot.com.pl/" target="_blank" class="home__single-link">
					<VLazyImage :src="images.logoDronspot" alt="Dronspot logo" class="home__single-logo" />
				</a>

				<a href="https://www.vetoquinol.pl/" target="_blank" class="home__single-link">
					<VLazyImage :src="images.logoVeto" alt="Vetoquinol logo" class="home__single-logo" />
				</a>
			</div>

			<div class="home__links">
				<BaseButton inline to="/docs/owu-vetoquinol.pdf" target="_blank" class="home__links-item">
					OWU
				</BaseButton>

				<BaseButton
					inline
					to="/docs/polityka-prywatnosci-vetoquinol.pdf"
					target="_blank"
					class="home__links-item"
				>
					Polityka Prywatności
				</BaseButton>
			</div>
		</footer>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import BaseButton from '@/components/base/button/BaseButton.vue';
import { useGetImage } from '@/composables';
// import MyPdf from '@/assets/docs/owu.pdf';

export default defineComponent({
	components: {
		BaseButton
	},

	setup() {
		const { getHomeImage } = useGetImage();

		return {
			images: {
				headline: getHomeImage('headline'),
				// Cat for some reason needs to be like this
				catMobile: require(`@/assets/images/view-home/cat-mobile.png`),
				dogMobile: getHomeImage('dog-mobile'),
				packshotsMobile: getHomeImage('packshots-mobile'),
				logoDrontal: getHomeImage('logo-drontal'),
				logoDronspot: getHomeImage('logo-dronspot'),
				logoVeto: getHomeImage('logo-veto')
			},
			docs: {
				// owu: require(`../assets/dosc/owu.pdf`),
				// pp: require(`../assets/dosc/polityka-prywatnosci.pdf`)
			}
		};
	}
});
</script>

<style lang="scss">
.home {
	display: grid;
	grid-auto-columns: 1fr;
	grid-template-rows: 1fr auto auto;
	width: 100%;
	min-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);
	position: relative;
	text-align: center;

	&__logos {
		@include flex-center;

		margin-left: -0.6rem;

		@include under-hd {
			margin-left: 0;
		}

		@include tablet-portrait {
			margin-left: -15px;
		}

		@include max-phablet {
			margin: 0;
			padding: 5px 0 0;
		}
	}

	&__single-logo {
		width: 100%;
	}

	&__single-link {
		display: inline-flex;
		transition: opacity $transition_default;

		&:hover,
		&:focus {
			opacity: 0.8;
		}

		&:nth-of-type(1),
		&:nth-of-type(3) {
			margin-top: -1rem;
		}

		&:nth-of-type(1) {
			@include under-hd {
				width: 64px * 2;
			}

			@include max-phablet {
				width: 64px * 1.3;
				margin: 0;
			}

			@include max-mobile-m {
				width: 64px;
			}
		}

		&:nth-of-type(2) {
			@include under-hd {
				width: 82px * 2;
			}

			@include max-phablet {
				width: 82px * 1.3;
				margin: 0;
			}

			@include max-mobile-m {
				width: 82px;
			}
		}

		&:nth-of-type(3) {
			@include under-hd {
				width: 75px * 2;
			}

			@include max-phablet {
				width: 75px * 1.3;
				margin: -5px 0 0;
			}

			@include max-mobile-m {
				width: 75px;
			}
		}

		& + & {
			margin-left: 6rem;

			@include tablet-portrait {
				margin-left: 60px;
			}

			@include max-phablet {
				margin-left: 7vw;
			}
		}
	}

	&__links {
		display: flex;
		gap: 2.4rem;
		margin-top: 1.6rem;
	}

	&__links-item {
		min-width: 0;
		min-height: 0;
		font-size: 2rem;

		@include max-phablet {
			font-size: 1.6rem;
		}

		&:focus {
			background-color: transparent;
		}
	}
}

.home__main {
	@include flex-center;

	position: relative;
	overflow: hidden;

	@include tablet-portrait {
		align-items: flex-start;
		padding-bottom: 324px;
	}

	@include max-phablet {
		align-items: flex-start;
		padding-bottom: 315px;
	}

	.home__main__inner {
		margin-top: -14vh;
		z-index: $z_index_base;

		@include bp(700, $height: true) {
			margin-top: -6vh;
		}

		@include tablet-portrait {
			margin-top: 6.6vh;
		}

		@include max-phablet {
			margin-top: 51px;
		}
	}

	.base-button {
		@include max-phablet {
			min-width: 212px;
		}
	}
}

.home__animal-img {
	display: block;
	position: absolute;
	bottom: 0;
	pointer-events: none;

	&.home__animal-img--cat {
		min-width: 48.5rem;
		margin-bottom: -9rem;
		left: calc(50% - 23.7vw - 24rem);

		@include max-desktop {
			min-width: 48.5rem * 0.85;
			max-width: 48.5rem * 0.85;
			left: calc(50% - 23.7vw - 20rem);
		}

		@include max-laptop {
			min-width: 48.5rem * 0.8;
			max-width: 48.5rem * 0.8;
			left: calc(50% - 27vw - 20rem);
		}

		@include bp(700, $height: true) {
			min-width: 48.5rem * 0.6;
			max-width: 48.5rem * 0.6;
			left: calc(50% - 27vw - 10rem);
		}

		@include bp(1200) {
			left: calc(50% - 30vw);
			left: calc(50% - 27vw - 25rem);
		}

		@include max-tablet {
			min-width: 283px;
			max-width: 283px;
			left: calc(50% - 29vw - 137px);
		}

		@include tablet-portrait {
			margin: 0;
			bottom: -20px;
			left: calc(50% - 31.5vw - 137px);
		}

		@include max-phablet {
			min-width: 136px;
			max-width: 136px;
			margin: 0;
			bottom: -56px;
			left: -1px;
		}
	}

	&.home__animal-img--dog {
		min-width: 59.7rem;
		margin-bottom: -2rem;
		right: calc(50% - 21vw - 30rem);

		// transform: translateX(50%);

		@include max-desktop {
			min-width: 59.7rem * 0.85;
			max-width: 59.7rem * 0.85;
			right: calc(50% - 21vw - 27rem);
		}

		@include max-laptop {
			min-width: 59.7rem * 0.8;
			max-width: 59.7rem * 0.8;
			right: calc(50% - 26vw - 20rem);
		}

		@include bp(700, $height: true) {
			min-width: 59.7rem * 0.7;
			max-width: 59.7rem * 0.7;
			right: calc(50% - 26vw - 18rem);
		}

		@include bp(1200) {
			right: calc(50% - 26vw - 23rem);
		}

		@include max-tablet {
			min-width: 377px;
			max-width: 377px;
			right: calc(50% - 30vw - 190px);
		}

		@include tablet-portrait {
			margin: 0;
			right: calc(50% - 25vw);
			right: calc(50% - 25vw - 190px);
			bottom: -44px;
		}

		@include max-phablet {
			min-width: 255px;
			max-width: 255px;
			margin: 0;
			right: -51px;
			right: calc(-50px);
			bottom: -2px;
		}
	}
}

.home__headline-logo {
	display: block;
	max-width: 54.2rem;
	margin: 0 auto;

	@include under-hd {
		max-width: 50rem;
	}

	@include bp(800, $height: true) {
		max-width: 45rem;
	}

	@include bp(700, $height: true) {
		max-width: 36rem;
	}

	@include tablet-portrait {
		max-width: 542px;
	}

	@include max-phablet {
		max-width: 275px;
	}
}

.home__headline-text {
	@include font('headline');

	margin-top: 3.9rem;
	padding: 0 15px;
	color: $color_cyan;
	text-align: center;

	@include bp(800, $height: true) {
		margin-top: 2rem;
	}

	@include tablet-portrait {
		margin-top: 37px;

		br {
			display: none;
		}
	}

	@include max-phablet {
		margin-top: 51px;
	}
}

.home__button-wrapper {
	margin-top: 2rem;

	@include tablet-portrait {
		margin-top: 31px;
	}
}

.home__bottom {
	height: 15.2rem;
	position: relative;
	color: white;
	background: $color_green;

	@include under-hd {
		height: 12rem;
	}

	@include tablet-portrait {
		height: 151px;
	}

	@include max-phablet {
		height: 154px;
	}

	.packshot {
		max-height: 52.3rem;

		// max-height: 33.4rem;
		position: absolute;
		bottom: -2px;
		left: 50%;
		transform: translate(-45.7%, 22%);

		@include under-hd {
			max-height: 47rem;

			// max-height: 30rem;
		}

		@include bp(800, $height: true) {
			max-height: 39.2rem;

			// max-height: 25rem;
		}

		@include bp(700, $height: true) {
			max-height: 28.2rem;

			// max-height: 18rem;
		}

		@include tablet-portrait {
			max-width: 96%;
			max-height: 333px;
			bottom: -2px;
			transform: translateX(-46.6%);
		}

		@include max-mobile {
			min-width: 270px;
			max-width: 270px;
			max-height: none;
			bottom: -5px;
			transform: translateX(-52.6%);
		}
	}
}

.home__footer {
	@include flex-center;

	flex-direction: column;
	min-height: 15.3rem;
	padding: 2.5rem 0 1.4rem;
	position: relative;
	background: $color_green;
	background: white;

	@include under-hd {
		min-height: 11rem;
	}

	@include tablet-portrait {
		min-height: 153px;
	}

	@include max-phablet {
		min-height: 94px;
	}
}
</style>
